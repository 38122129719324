import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Content from "../components/App/Content"
import PageBanner from "../components/Common/PageBanner"
import testImage from "../assets/images/lexikon/test.png"
import LexikonSidebar from "../components/lexikon/LexikonSidebar"
import { Helmet } from "react-helmet"
import NetstorsysHero from "../components/heros/NetstorsysHero"
import NetversysHero from "../components/heros/NetversysHero"
import OurBlog from "../components/Index/OurBlog"

const LexikonSingleItemPage = (
  {
    data: {
      markdownRemark: qaItem,
      blog,
      site: {
        siteMetadata: {
          siteUrl
        }
      }
    }
  }) => {
  return (
    <Layout>
      <Helmet>
        <title>Lexikon: {qaItem.frontmatter.question}</title>

        <meta name={"description"} content={qaItem.frontmatter.description || qaItem.excerpt} />
        <meta name="twitter:card" content="summary_large_image" />
        {qaItem.frontmatter.question &&
          <meta name="twitter:title" content={"LOGENTIS Lexikon. Thema: " +
            qaItem.frontmatter.question} />
        }
        <meta name="twitter:description" content={qaItem.frontmatter.descriptionTwitter || qaItem.frontmatter.description || qaItem.excerpt} />
        <meta name="twitter:image" content={siteUrl + "/images/og/lexikon-logentis-og.png"} />
        <meta property={"og:image"} content={siteUrl + "/images/og/lexikon-logentis-og.png"} />
        <meta property={"og:description"} content={qaItem.frontmatter.description || qaItem.excerpt} />
        <meta property={"og:title"} content={qaItem.frontmatter.question} />

        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "${qaItem.frontmatter.question}",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "${qaItem.excerpt.replace(/\n/g, "").replace(/"/g, "'")}"
            }
          }]
        }
      `}</script>
      </Helmet>
      <Navbar />
      <PageBanner
        bannerClassname={"page-title-area2"}
        pageTitle="Lexikon"
        homePageText="Über uns"
        homePageUrl="/"
        makeH1={false}
        activePageText="Lexikon"
        breadcrumbs={[["Home", "/"]]}
      />
      <section className="history-area mt-5 pb-70">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className={"d-flex"}>
                <div style={{ alignSelf: "flex-start" }} className={"mr-4"}>
                  {qaItem.frontmatter.icon &&
                    <GatsbyImage alt={qaItem.frontmatter.icon + " Logo"}
                                 image={getImage(qaItem.frontmatter.icon)} />
                  }
                  {!qaItem.frontmatter.icon && <img style={{ width: "100px" }} src={testImage}
                                                    alt={'title'} />}
                </div>
                <div className={"d-flex flex-column"} style={{ flexGrow: 1 }}>
                  <div><h1 className={"pt-3"}>{qaItem.frontmatter.question}</h1></div>
                  <div><span className={"badge badge-primary"}>{qaItem.frontmatter.category}</span></div>
                </div>
              </div>

              <Content style={{ textAlign: "justify", hyphens: "auto" }}
                       htmlContent={qaItem.html}
              />
            </Col>
            <Col lg={4} md={12}>
              <div className={"ml-3 mb-3"}>
                <Link to={"/lexikon/"}>
                  <button type="button" className="btn btn-outline-primary"><i className="bx bx-chevron-left" />Zurück
                    zur Übersicht
                  </button>
                </Link>
              </div>
              <LexikonSidebar />
            </Col>
          </Row>
        </Container>
      </section>

      {qaItem.frontmatter.category === "Warehouse Management" && <NetstorsysHero />}
      {qaItem.frontmatter.category === "Versandlogistik" && <NetversysHero />}
      {qaItem.frontmatter.category === "Logistik / Allgemein" && <NetversysHero />}
      <OurBlog lastPosts={blog.edges} />
      <Footer />
    </Layout>
  )
}

export default LexikonSingleItemPage

export const pageQuery = graphql`
  query qaById($id: String!) {
    site {
        siteMetadata {
            siteUrl
        }
    }
    markdownRemark(id: { eq: $id }) {
      id
      rawMarkdownBody
      excerpt(pruneLength: 170)
      html
      frontmatter {
        question
        description
        descriptionTwitter
        category        
      }
      fields {
        slug
        collection
      }
    }
    blog: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC },
        limit: 3,
        filter: { fields: { collection: { eq: "blog" }}, frontmatter: {disabled: {eq: false}}}
      )
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 400)
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD.MM.YYYY")
                title
                featuredimage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                authorFull {
                    name
                    authorimage {
                        childImageSharp {
                            gatsbyImageData(width: 40)
                        }
                    }
                }
              }
            }
        }
     }
  }
`
